/**
 * API urls for OTA calls
 */

export const OTA_API = [
  {
    apiUrl: "/api/OTA.AddIMSI",
    apiMethod: "POST",
    apiHeader: "addIMSI",
    showReq: false,
    requestParams: [
      {
        name: "eid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Stands for eUICC identifier. Identifies the targeted card.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "89065012012341234000000000007107",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "iccid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Identifies the targeted Profile",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "893107101705091100",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "imsiIndex",
        value: "",
        inputType: "number",
        rules: [
          {
            name: "Description",
            value:
              "Defines the Imsi index of the IMSI to add on the profile. Request will be rejected if this index is already used on the card.",
          },
          {
            name: "Type",
            value: "integer($int32)",
          },
          {
            name: "Example",
            value: "1",
          },
          {
            name: "Minimum Value",
            value: "1",
          },
          {
            name: "Maximum Value",
            value: "10",
          },
          {
            name: "exclusiveMinimum",
            value: false,
          },
          {
            name: "Exclusive Maximum",
            value: false,
          },
        ],
        validationRules: {
          required: true,
          minimum: 1,
          maximum: 10,
          exclusiveMinimum: false,
          exclusiveMaximum: false,
        },
      },
      {
        name: "spnDisplayIndicator",
        value: "",
        inputType: "number",
        rules: [
          {
            name: "Description",
            value: "Display Indicator for SPN",
          },
          {
            name: "Type",
            value: "integer($int32)",
          },
          {
            name: "Example",
            value: "2",
          },
          {
            name: "Minimum Value",
            value: "1",
          },
          {
            name: "Maximum Value",
            value: "10",
          },
          {
            name: "exclusiveMinimum",
            value: false,
          },
          {
            name: "Exclusive Maximum",
            value: false,
          },
        ],
        validationRules: {
          required: true,
          minimum: 1,
          maximum: 10,
          exclusiveMinimum: false,
          exclusiveMaximum: false,
        },
      },
      {
        name: "imsiValue",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the Imsi Value of the IMSI to add on the profile. It should be 15 characters long exactly.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "123456789012345",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "imsiLabel",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the Imsi Description of the IMSI to add on the profile. It should be 19 characters or less.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "Vodafone_Australia",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "smscAddress",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the SMSC address of the IMSI to add on the profile. It should be 40 characters or less.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "+6597371212",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "spn",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the SPN (Service provider Name) address of the IMSI to add on the profile. It should be 17 characters or less and start with a +.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "spn.example.com",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "mccList",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the MCC (Mobile Coutry Code) that will be associated with the IMSI to add on the card. Must be consituted of concatenated 3 bytes-MCCs.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "111222456",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      // {
      //     name: 'callback',
      //     value: '',
      //     inputType: 'string',
      //     rules: [
      //         {
      //             name: 'Description',
      //             value: 'Documents the callback URL to be informed of the final status of the request.'
      //         },
      //         {
      //             name: 'Type',
      //             value: 'string'
      //         },
      //         {
      //             name: 'Example',
      //             value: 'http://callback.example.com:1234/'
      //         }
      //     ],
      //     validationRules:
      //     {
      //         required: false
      //     }

      // }
    ],
  },
  {
    apiUrl: "/api/OTA.DeleteIMSI",
    apiMethod: "POST",
    apiHeader: "deleteIMSI",
    showReq: false,
    requestParams: [
      {
        name: "eid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Stands for eUICC identifier. Identifies the targeted card.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "89065012012341234000000000007107",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "iccid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Identifies the targeted Profile",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "893107101705091100",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "imsiValue",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the Imsi Value of the IMSI to add on the profile. It should be 15 characters long exactly.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "123456789012345",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      // {
      //     name: 'callback',
      //     value: '',
      //     inputType: 'string',
      //     rules: [
      //         {
      //             name: 'Description',
      //             value: 'Documents the callback URL to be informed of the final status of the request.'
      //         },
      //         {
      //             name: 'Type',
      //             value: 'string'
      //         },
      //         {
      //             name: 'Example',
      //             value: 'http://callback.example.com:1234/'
      //         }
      //     ],
      //     validationRules:
      //     {
      //         required: false
      //     }

      // }
    ],
  },
  {
    apiUrl: "/api/OTA.UpdateFPLMN",
    apiMethod: "POST",
    apiHeader: "updateFPLMN",
    showReq: false,
    requestParams: [
      {
        name: "eid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Stands for eUICC identifier. Identifies the targeted card.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "89065012012341234000000000007107",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "iccid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Identifies the targeted Profile",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "893107101705091100",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "fplmnList",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the FPLMN to be update in the card. Must be consituted of a multiple 5 or 6 Bytes MCCMNCs seperated by commas",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "12345,67890",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      // {
      //     name: 'callback',
      //     value: '',
      //     inputType: 'string',
      //     rules: [
      //         {
      //             name: 'Description',
      //             value: 'Documents the callback URL to be informed of the final status of the request.'
      //         },
      //         {
      //             name: 'Type',
      //             value: 'string'
      //         },
      //         {
      //             name: 'Example',
      //             value: 'http://callback.example.com:1234/'
      //         }
      //     ],
      //     validationRules:
      //     {
      //         required: false
      //     }

      // }
    ],
  },
  {
    apiUrl: "/api/OTA.UpdateIMSI",
    apiMethod: "POST",
    apiHeader: "updateIMSI",
    showReq: false,
    requestParams: [
      {
        name: "eid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Stands for eUICC identifier. Identifies the targeted card.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "89065012012341234000000000007107",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "iccid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Identifies the targeted Profile",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "893107101705091100",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "imsiIndex",
        value: "",
        inputType: "number",
        rules: [
          {
            name: "Description",
            value:
              "Defines the Imsi index of the IMSI to add on the profile. Request will be rejected if this index is already used on the card.",
          },
          {
            name: "Type",
            value: "integer($int32)",
          },
          {
            name: "Example",
            value: "1",
          },
          {
            name: "Minimum Value",
            value: "1",
          },
          {
            name: "Maximum Value",
            value: "10",
          },
          {
            name: "Exclusive Minimum",
            value: false,
          },
          {
            name: "Exclusive Maximum",
            value: false,
          },
        ],
        validationRules: {
          required: true,
          minimum: 1,
          maximum: 10,
          exclusiveMinimum: false,
          exclusiveMaximum: false,
        },
      },
      {
        name: "spnDisplayIndicator",
        value: "",
        inputType: "number",
        rules: [
          {
            name: "Description",
            value: "Display Indicator for SPN",
          },
          {
            name: "Type",
            value: "integer($int32)",
          },
          {
            name: "Example",
            value: "2",
          },
          {
            name: "Minimum Value",
            value: "1",
          },
          {
            name: "Maximum Value",
            value: "10",
          },
          {
            name: "Exclusive Minimum",
            value: false,
          },
          {
            name: "Exclusive Maximum",
            value: false,
          },
        ],
        validationRules: {
          required: true,
          minimum: 1,
          maximum: 10,
          exclusiveMinimum: false,
          exclusiveMaximum: false,
        },
      },
      {
        name: "imsiValue",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the Imsi Value of the IMSI to add on the profile. It should be 15 characters long exactly.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "123456789012345",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "imsiLabel",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the Imsi Description of the IMSI to add on the profile. It should be 19 characters or less.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "Vodafone_Australia",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "smscAddress",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the SMSC address of the IMSI to add on the profile. It should be 40 characters or less.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "+6597371212",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "spn",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the SPN (Service provider Name) address of the IMSI to add on the profile. It should be 17 characters or less and start with a +.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "spn.example.com",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "mccList",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the MCC (Mobile Coutry Code) that will be associated with the IMSI to add on the card. Must be consituted of concatenated 3 bytes-MCCs.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "111222456",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      // {
      //     name: 'callback',
      //     value: '',
      //     inputType: 'string',
      //     rules: [
      //         {
      //             name: 'Description',
      //             value: 'Documents the callback URL to be informed of the final status of the request.'
      //         },
      //         {
      //             name: 'Type',
      //             value: 'string'
      //         },
      //         {
      //             name: 'Example',
      //             value: 'http://callback.example.com:1234/'
      //         }
      //     ],
      //     validationRules:
      //     {
      //         required: false
      //     }

      // }
    ],
  },
  {
    apiUrl: "/api/OTA.UpdateMCC",
    apiMethod: "POST",
    apiHeader: "Updating MCC value",
    showReq: false,
    requestParams: [
      {
        name: "eid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Stands for eUICC identifier. Identifies the targeted card.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "89065012012341234000000000007107",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "iccid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Identifies the targeted Profile",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "893107101705091100",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "preferred_PLMN",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Identifies the preferred PLMN",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "12D4DD01",
          },
        ],
        validationRules: {
          required: true,
        },
      },
    ],
  },
  {
    apiUrl: "/api/OTA.UpdatePLMN",
    apiMethod: "POST",
    apiHeader: "updatePLMN",
    showReq: false,
    requestParams: [
      {
        name: "eid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Stands for eUICC identifier. Identifies the targeted card.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "89065012012341234000000000007107",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "iccid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Identifies the targeted Profile",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "893107101705091100",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      // {
      //     name: 'callback',
      //     value: '',
      //     inputType: 'string',
      //     rules: [
      //         {
      //             name: 'Description',
      //             value: 'Documents the callback URL to be informed of the final status of the request.'
      //         },
      //         {
      //             name: 'Type',
      //             value: 'string'
      //         },
      //         {
      //             name: 'Example',
      //             value: 'http://callback.example.com:1234/'
      //         }
      //     ],
      //     validationRules:
      //     {
      //         required: false
      //     }
      // },
      {
        name: "plmnList",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the PLMN to be update in the card. Must be consituted of a multiple 5 or 6 Bytes MCCMNCs seperated by commas",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "12345,67890",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "plmnWactList",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the PLMN Wact to be update in the card. Must be consituted of a multiple 5 or 6 Bytes MCCMNCs seperated by commas",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "12345,67890",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "hplmnList",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the HPLMN to be update in the card. Must be consituted of a multiple 5 or 6 Bytes MCCMNCs seperated by commas",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "12345,67890",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "oplmnList",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the OPLMN to be update in the card. Must be consituted of a multiple 5 or 6 Bytes MCCMNCs seperated by commas",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "12345,67890",
          },
        ],
        validationRules: {
          required: true,
        },
      },
    ],
  },
  {
    apiUrl: "/api/OTA.UpdateSpn",
    apiMethod: "POST",
    apiHeader: "UpdateSpn",
    showReq: false,
    requestParams: [
      {
        name: "eid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Stands for eUICC identifier. Identifies the targeted card.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "89065012012341234000000000007107",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "iccid",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value: "Identifies the targeted Profile",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "893107101705091100",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "spn",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Defines the SPN (Service provider Name) address of the IMSI to add on the profile. It should be 17 characters or less and start with a +.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "spn.example.com",
          },
        ],
        validationRules: {
          required: true,
        },
      },
      {
        name: "refresh_type",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Allows the user to choose the type of SIM refresh: simreset or fullinit.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "fullinit",
          },
        ],
        validationRules: {
          required: false,
        },
      },
      {
        name: "sendNow",
        value: true,
        inputType: "boolean",
        rules: [
          {
            name: "Description",
            value:
              "This will let the OTA know to use SMS instead of OTA w/o SMS",
          },
          {
            name: "Type",
            value: "boolean",
          },
          {
            name: "Example",
            value: "true",
          },
        ],
        validationRules: {
          required: false,
        },
      },
      {
        name: "requestorFreeText",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Allows API user to type in anything they want. This RequestorFreeText will be passed back in the callback",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "example",
          },
        ],
        validationRules: {
          required: false,
        },
      },

      {
        name: "callback",
        value: "",
        inputType: "string",
        rules: [
          {
            name: "Description",
            value:
              "Documents the callback URL to be informed of the final status of the request.",
          },
          {
            name: "Type",
            value: "string",
          },
          {
            name: "Example",
            value: "http://callback.example.com:1234/",
          },
        ],
        validationRules: {
          required: false,
        },
      },
    ],
  },
];

export const API_TYPE = [{ name: "application/json", value: "json" }];
// export const API_URL = "https://eos1-15-dev.oasis-smartsim.com:9090";
// export const API_URL = "https://plcm-dev.oasis-smartsim.com:8444";

//  export const API_URL = 'https://localhost:9090';
export const API_URL = "https://eos1-airmob.oasis-smartsim.com:9090";

export const SWAGGER_UI = API_URL + "/swagger-ui.html#/";

export const FORGOT_PASSWORD_EMAIL = "supportfr@oasis-smartsim.com";

export const WEBSITE_LOGO = "OasislogoHD.png";
export const WEBSITE_TITLE = "OASIS Subscription Manager";
export const WEBSITE_SUB_TITLE = "EOS 1.5.11 : OASIS Subscription Manager";

export const MODAL_PAGINATION = 5;
export const PAGE_PAGINATION = 50;
export const SUB_PAGE_PAGINATION = 10;

export const OTA_API_INTERVAL = 25000; //milisec
export const OTA_LOG_LIMIT = 20;

export const TIME_SHORT = 1000;
export const TIME_MEDIUM = 1500;
export const TIME_LONG = 2500;
export const TIME_ADD_USER = 25000;

/** time is added in seconds */
export const SESSION_TIMEOUT = 900;
export const SESSION_TIMEOUT_WARNING = 885;

export const ENABLE_OTA_FOR_DISABLED = false;

export const MESSAGES = {
  success: "Success",
  failure: "Failure",
  missingTitle: "Missing",
  addProfileEmpty: "Please select atleast one Profile",
  delete: "Delete",
  deleteSuccess: "User is deleted successfully",
  update: "Update",
  updateSuccess: "User is updated successfully",
  deleteLoggedInUser: "Cannot delete logged in User",
  sessionExpiry: "Your session has expired. Please login again",
  auditSuccess: "Your card is audited successfully",
  updateMSISDNEmpty: "MSISDN value cannot be empty",
  downloadProfileConfirmation: "Do you want to download the profile?",
  deleteProfileConfirmation: "Do you want to Delete this Profile?",
  enableProfileConfirmation: "Do you want to Enable this Profile?",
  auditCardConfirmation: "Do you want to Audit this Card?",
  enterMSISDN: "Enter the new MSISDN value",
  deleteUserConfirmation: "Do you want to Delete this User?",
};
